import React from 'react'

const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
};

const RegistrationButton: React.FC = () => {
    return (
        <button onClick={() => openInNewTab("https://link.accelist.com/Registration")} type="button" className="text-white bg-defaultBlue hover:bg-dark focus:ring-darkBlue font-medium rounded-lg md:text-lg text-base px-4 py-2 text-center dark:bg-defaultBlue dark:hover:bg-darkBlue dark:focus:ring-defaultBlue">Registration</button>
    )
}

export default RegistrationButton

