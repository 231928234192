import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

export const NavLink: React.FC<{
    href?: string,
    id?:string,
    style?: React.CSSProperties,
    children: React.ReactNode
}> = ({ href, children, style,id }) => {

    const router = useRouter();

    function getAnchorClassNames() {
        if (href) {
            if (href === '/') {
                if (router.pathname === '/') {
                    return 'text-defaultBlue';
                }
            } else if (router.pathname.startsWith(href)) {
                return 'text-defaultBlue';
            }
        }
        if(id == 'footerList'){
            return 'text-black'
        }
        return 'text-gray-500';
    }

    return (
        <Link href={href ?? '#'} className={getAnchorClassNames()} style={style}>
            {children}
        </Link>
    );
}
