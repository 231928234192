import React from 'react';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faGlobe, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from './NavLink';

export default function Footer() {

  const footerMenuItems = [
    {
      label: 'Home',
      href: '/',

    },
    {
      label: 'IIT Challenges Philosophy',
      href: '/iit-philosophy',
    },
    {
      label: 'IIT Challenges Program', href: '/iit-program',
    },
    {
      label: 'Partner',
      href: '',
    },
    {
      label: 'Register',
      href: 'https://link.accelist.com/Registration',
    }
  ];

  const iitChallengeItems = [
    {
      label: 'Robot Race Category',
      href: '/iit-program'
    },
    {
      label: 'Presentation Category',
      href: '/iit-program'
    },
    {
      label: 'Software Programming Category',
      href: '/iit-program'
    },
    {
      label: 'Workshop',
      href: '/iit-program'
    },
  ];

  return (
    <footer className="py-12 mx-auto bg-gray-300 mt-24">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 md:gap-8 gap-10 px-2">
        <div className="flex flex-col">
          <div className="flex items-center mb-4">
            <img alt="Logo" src="/images/iit-logo.webp" />
          </div>
          <div className="mb-4 flex items-center px-1">
            <FontAwesomeIcon icon={faLocationDot} className='mr-4' style={{ color: "#00B0F0", fontSize: "1.25rem" }} />
            <p className='text-sm justify-between md:text-base'>Brooklyn Office Tower B, 7th Floor, Unit P. Alam Sutera Boulevard. Banten, Indonesia</p>
          </div>
          <div className="mb-4 flex items-center px-1">
            <FontAwesomeIcon icon={faEnvelope} className='mr-4' style={{ color: "#00B0F0", fontSize: "1.25rem" }} />
            <p className='text-sm justify-between md:text-base'>support@iitchallenge.com</p>
          </div>
          <div className="mb-4 flex items-center px-1">
            <FontAwesomeIcon icon={faPhone} className='mr-4' style={{ color: "#00B0F0", fontSize: "1.25rem" }} />
            <p className='text-sm justify-between md:text-base'>6281215636018</p>
          </div>
          <div className="flex items-start px-1">
            <FontAwesomeIcon icon={faGlobe} className='mr-4' style={{ color: "#00B0F0", fontSize: "1.25rem" }} />
            <p>
              <a className='text-sm justify-between md:text-base' target="_blank" rel="noreferrer" href="https://www.accelist.com">https://www.accelist.com</a>
              <br />
              <a className='text-sm justify-between md:text-base' target="_blank" rel="noreferrer" href="https://ausrobotics.org/wit2023-the-8th-world-innovative-technology-challenge/">https://ausrobotics.org/wit2023-the-8th-world-innovative-technology-challenge/</a>
              <br />
              <a className='text-sm justify-between md:text-base' target="_blank" rel="noreferrer" href="https://ukrida.ac.id/">https://ukrida.ac.id/</a>
            </p>
          </div>
        </div>
        <div className="mx-auto grid grid-cols-2  gap-8">
          <div>
            <h3 className="text-sm md:text-lg lg:text-xl font-semibold text-defaultBlue px-2">Main Menu</h3>
            <ul>
              {footerMenuItems.map((item, index) => (
                <li className="mt-2 text-sm justify-between md:text-base lg:text-base px-2" key={index}>
                  <NavLink href={item.href} id='footerList'>
                    {item.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-sm md:text-lg lg:text-xl  font-semibold mb-4 text-defaultBlue">IIT Challenges 2024</h3>
            <ul>
              {iitChallengeItems.map((item, index) => (
                <li className='mt-2 text-sm justify-between md:text-base lg:text-base' key={index}>
                  <Link href={item.href}>{item.label}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
